
export type MsMhdProps = {
    mhd: Date
}

const dateFormatter = Intl.DateTimeFormat('de-DE', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
})
const MsMhd = ({mhd}: MsMhdProps): string => {
    return mhd ? ` | MHD: ${dateFormatter.format(mhd)}` : ''
}
MsMhd.props = {
    mhd: {type: Object},
}
export default MsMhd
