
import {Options, Vue} from 'vue-class-component'
import {Prop, Watch} from 'vue-property-decorator'

import SwiperCore, {Navigation, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue'
import SvgSprite from '@/components/SvgSprite.vue'
import MsLoader from '@/components/MsLoader.vue'
import MsImage from '@/components/MsImage.vue'
import MsMhd from '@/components/MsMhd.vue'
import MsNav from '@/components/MsNav.vue'

import {getSweet, getSweets} from '@/helper/data'
import type {Sweet} from '@/helper/data'

SwiperCore.use([Navigation, Pagination])

@Options({
    name: 'Detail',
    components: {
        Swiper,
        SwiperSlide,
        SvgSprite,
        MsLoader,
        MsImage,
        MsMhd,
        MsNav,
    },
})
export default class Detail extends Vue {
    @Prop({type: String, required: true})
    readonly id!: string

    sweet: Sweet | null = null
    total = 0

    @Watch('id', {immediate: true})
    async setSweet(): Promise<void> {
        const sweet = await getSweet(parseInt(this.id))
        if (!sweet) {
            this.$router.replace({name: 'home'})
            return
        }
        this.sweet = sweet
        this.total = (await getSweets()).length
    }
}
