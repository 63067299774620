
import {h, VNode} from 'vue'
import {RouterLink} from 'vue-router'

import SvgSprite from './SvgSprite.vue'

export type MsNavProps = {
    id: number
    total: number
}

const MsNav = ({id, total}: MsNavProps): VNode | VNode[] => {
    const children = [
        h(
            RouterLink,
            {
                class: 'nav-home',
                key: 'nav-home',
                to: {name: 'home'},
            },
            () => h(SvgSprite, {icon: 'grid'}),
        ),
    ]

    if (id > 0) {
        children.unshift(
            h(
                RouterLink,
                {
                    class: 'nav-prev',
                    key: 'nav-prev',
                    to: {name: 'detail', params: {id: id - 1}},
                },
                () => h(SvgSprite, {icon: 'chevron-left'}),
            ),
        )
    }

    if (id < total - 1) {
        children.push(
            h(
                RouterLink,
                {
                    class: 'nav-next',
                    key: 'nav-next',
                    to: {name: 'detail', params: {id: id + 1}},
                },
                () => h(SvgSprite, {icon: 'chevron-right'}),
            ),
        )
    }

    return h('nav', {class: 'detail-nav'}, children)
}
MsNav.props = {
    id: {type: Number, required: true},
    total: {type: Number, required: true},
}
export default MsNav
