<template>
    <div class="detail">
        <ms-loader :loading="!sweet">
            <div class="header-image">
                <swiper
                    v-if="sweet.images.length > 1"
                    :slides-per-view="1"
                    navigation
                    :pagination="{clickable: true}"
                >
                    <swiper-slide v-for="id in sweet.images" :key="`slide-${id}`">
                        <ms-image :id="id" />
                    </swiper-slide>
                </swiper>
                <ms-image v-else :id="sweet.images[0]" />
            </div>

            <div class="text-content content">
                <small>{{ sweet.country.name }}<ms-mhd :mhd="sweet.mhd" /></small>
                <h1>{{ sweet.name }}</h1>

                <p v-for="(text, index) in sweet.description" :key="index">{{ text }}</p>

                <ul v-if="sweet.links && sweet.links.length" class="links">
                    <li v-for="link in sweet.links" :key="link">
                        <a :href="link">{{ link }}</a>
                    </li>
                </ul>

                <hr />

                <div class="small-print">
                    <p class="ingredients">Zutaten: {{ sweet.ingredients }}</p>
                    <div v-if="sweet.comments" class="comments">
                        <p v-for="(text, index) in sweet.comments" :key="index">{{ text }}</p>
                    </div>
                </div>

                <hr />

                <ms-nav :id="sweet.id" :total="total" />
            </div>
        </ms-loader>
    </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import {Prop, Watch} from 'vue-property-decorator'

import SwiperCore, {Navigation, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue'
import SvgSprite from '@/components/SvgSprite.vue'
import MsLoader from '@/components/MsLoader.vue'
import MsImage from '@/components/MsImage.vue'
import MsMhd from '@/components/MsMhd.vue'
import MsNav from '@/components/MsNav.vue'

import {getSweet, getSweets} from '@/helper/data'
import type {Sweet} from '@/helper/data'

SwiperCore.use([Navigation, Pagination])

@Options({
    name: 'Detail',
    components: {
        Swiper,
        SwiperSlide,
        SvgSprite,
        MsLoader,
        MsImage,
        MsMhd,
        MsNav,
    },
})
export default class Detail extends Vue {
    @Prop({type: String, required: true})
    readonly id!: string

    sweet: Sweet | null = null
    total = 0

    @Watch('id', {immediate: true})
    async setSweet(): Promise<void> {
        const sweet = await getSweet(parseInt(this.id))
        if (!sweet) {
            this.$router.replace({name: 'home'})
            return
        }
        this.sweet = sweet
        this.total = (await getSweets()).length
    }
}
</script>

<style lang="sass" scoped>
.header-image
    +ratio(percentage(2/3))

.content
    padding: 1.5em 0 2.5em

h1
    margin-top: 0

.links
    margin: 3em 0 1em
    padding: 0
    list-style: none
    font-size: to-rem(14px)

hr
    margin: 1em 0 .75em

.small-print
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 1.5 * $padding-side
    @extend %font-small-1
</style>
